var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "d-none d-md-block" }, [
      _c("div", { staticClass: "cm-header-pc" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("../img/logo.svg") },
          on: {
            click: function ($event) {
              return _vm.onChange("Borrow", true)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nav-bar" },
          _vm._l(_vm.tabList, function (item) {
            return _c(
              "div",
              {
                key: item.name,
                staticClass: "nav",
                class: {
                  "nav-active": _vm.$route.name === item.name,
                },
                on: {
                  click: function ($event) {
                    return _vm.onChange(item.name)
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t(item.title)))]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "line",
                  class: {
                    "line-full": _vm.$route.name === item.name,
                  },
                }),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "language" }, [
          _c(
            "span",
            {
              class: { active: _vm.$i18n.locale === "en" },
              on: {
                click: function ($event) {
                  return _vm.switchLanguage("en")
                },
              },
            },
            [_vm._v("EN")]
          ),
          _c("span", [_vm._v("/")]),
          _c(
            "span",
            {
              class: { active: _vm.$i18n.locale === "es" },
              on: {
                click: function ($event) {
                  return _vm.switchLanguage("es")
                },
              },
            },
            [_vm._v("ES")]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-block d-md-none" }, [
      _c("div", { staticClass: "cm-header-mobile" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("../img/logo.svg") },
          on: {
            click: function ($event) {
              return _vm.onChange("Borrow", true)
            },
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "extend",
          attrs: { src: require("../img/extend.svg") },
          on: { click: _vm.onExtend },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "extend-box", class: { "is-extend": _vm.isExtend } },
          [
            _c("div", { staticClass: "item-title" }, [
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("../img/logo.svg") },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "extend",
                attrs: { src: require("../img/close.svg") },
                on: { click: _vm.onExtend },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item-wrap" },
              [
                _vm._l(_vm.tabList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      staticClass: "item",
                      on: {
                        click: function ($event) {
                          return _vm.onChange(item.name)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "left",
                          class: {
                            "nav-active": _vm.$route.name === item.name,
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t(item.title)) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "right",
                        attrs: { src: require("../img/arrow.svg") },
                      }),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "left" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "language" }, [
                    _c(
                      "span",
                      {
                        class: { active: _vm.$i18n.locale === "en" },
                        on: {
                          click: function ($event) {
                            return _vm.switchLanguage("en")
                          },
                        },
                      },
                      [_vm._v("EN")]
                    ),
                    _c("span", [_vm._v("/")]),
                    _c(
                      "span",
                      {
                        class: { active: _vm.$i18n.locale === "es" },
                        on: {
                          click: function ($event) {
                            return _vm.switchLanguage("es")
                          },
                        },
                      },
                      [_vm._v("ES")]
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }