<!--
 * @Author: zhaoyang
 * @Date: 2023-11-09 21:15:58
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-11-10 11:19:43
-->

<template>
    <div
        class="credmex-new"
        :class="{[`credmex-new-${$route.name}`]: showAnimation}"
    >
        <cm-header />
        <router-view :show-animation="showAnimation" />
        <div
            id="cm-footer"
            class="anmao"
        />
        <cm-footer />
        <cm-guide v-show="showGuide" />
    </div>
</template>

<script>
import CmFooter from './component/cm-footer.vue';
import CmGuide from './component/cm-guide.vue';
import CmHeader from './component/cm-header.vue';

export default {
    name: 'IndexNew',

    components: {
        CmHeader,
        CmFooter,
        CmGuide
    },

    data() {
        return {
            showGuide: false,
            showAnimation: true
        };
    },

    watch: {
        $route() {
            this.showAnimation = false;
        }
    },

    mounted() {
        this.showAnimation = !(window.innerWidth <= 768);
        window.addEventListener('scroll', () => {
            this.showAnimation = false;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            this.showGuide = scrollTop > window.innerHeight;
        });
    }
};

</script>

<style lang="scss">
@import "~credmex-new/common/style/index.scss";

.credmex-new {
    // width: 1440px;
    margin: 0 auto;

    &-Borrow {
        height: 100vh;
        overflow: hidden;
        animation: fade-overflow 0.1s linear 2s forwards;

        @keyframes fade-overflow {
            100% {
                height: auto;
                overflow: auto;
            }
        }
    }

    @media screen and (max-width: $screen-md) {
        width: 100%;
        min-width: auto;
    }
}
</style>
