/**
 * @Author: liuzhenkun
 * @Date: 2017/12/29-下午5:22
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-10-11 15:50:47
 */

import http from 'ssr-common/vue/vue-http';

export const api = {
    getAccountDetails: '/api/cashloan/accountDetails', // profile

    getAgreementByKey: '/api/operation/agreement/getByKey', // 获取协议
    getStaticAgreementByKey: '/api/operation/agreement/getContentByKey', // 获取静态协议
    getStaticTextByKey: '/api/operation/staticText/getByKey', // 获取markdown文案
    getContact: '/api/official/getContact', // 获取官网电话和邮件

    // 聚信力相关
    getJuxinliInfo: '/api/loan/getJuxinliInfo',
    updateJuxinliDataStatus: '/api/loan/updateJuxinliDatasourceStatus',
    finishJuxinliAuthorization: '/api/loan/finishJuxinliAuthorization'

};

export default {
    getAccountDetails() {
        return http.get(api.getAccountDetails);
    },

    getAgreementByKey(params) {
        return http.get(api.getAgreementByKey, {params});
    },

    getStaticAgreementByKey(params) {
        return http.get(api.getStaticAgreementByKey, {params});
    },

    getStaticTextByKey(params) {
        return http.get(api.getStaticTextByKey, {params});
    },

    getJuxinliInfo() {
        return http.get(api.getJuxinliInfo);
    },

    updateJuxinliDataStatus(data) {
        return http.post(api.updateJuxinliDataStatus, data);
    },

    finishJuxinliAuthorization() {
        return http.get(api.finishJuxinliAuthorization);
    },

    getContact(data) {
        return http.get(api.getContact, data);
    }
};
