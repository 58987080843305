<!--
 * @Author: zhaoyang
 * @Date: 2023-11-09 21:28:03
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-11-30 19:08:01
-->

<template>
    <div class="footer">
        <div class="d-none d-md-block">
            <div class="cm-footer-pc">
                <div class="pc-wrap">
                    <div class="pc-top">
                        <div class="pc-top-left">
                            <div class="foot-title">
                                {{ $t('newFooter.title') }}
                            </div>
                            <div class="footer-ewm">
                                <img
                                    class="qr-pic"
                                    src="../img/new-qrcode.png"
                                >
                                <div class="footer-ewm-right">
                                    <img
                                        class="logo-item"
                                        src="../component/borrow/banner/img/appstore-pc.png"
                                        alt=""
                                        @click="handleDownload('apple')"
                                    >
                                    <img
                                        class="logo-item"
                                        src="../component/borrow/banner/img/google-play-pc.png"
                                        alt=""
                                        @click="handleDownload('google')"
                                    >
                                </div>
                                <!-- <div class="inner0">
                                    <div class="inner0-left">
                                        {{ $t('newFooter.download') }}
                                    </div>
                                    <img
                                        src="../img/code.png"
                                        class="inner0-right"
                                    >
                                </div> -->
                            </div>
                        </div>
                        <div
                            v-if="!pptShow"
                            class="pc-top-right"
                        >
                            <div class="pc-top-right-item">
                                <div class="item-title">
                                    Credmex
                                </div>
                                <div class="item-content">
                                    <div
                                        v-for="(item, idx) in list"
                                        :key="idx"
                                        class="content link"
                                        @click="goPage(item.name)"
                                    >
                                        {{ $t(item.text) }}
                                    </div>
                                </div>
                            </div>
                            <div class="pc-top-right-item">
                                <div class="item-title">
                                    {{ $t('newFooter.customer') }}
                                </div>
                                <div class="item-content">
                                    <div
                                        v-for="(item, idx) in list1"
                                        :key="idx"
                                        class="content link"
                                        @click="goOtherPage(item)"
                                    >
                                        {{ $t(item.text) }}
                                    </div>
                                </div>

                                <div
                                    class="item-title"
                                    style="margin-top: 48px;"
                                >
                                    {{ $t('newFooter.social') }}
                                </div>

                                <div class="icon-wrap">
                                    <img
                                        v-for="(item, idx) in list3"
                                        :key="idx"
                                        :src="item.icon"
                                        class="icon link"
                                        @click="goSocialPage(item.url)"
                                    >
                                </div>
                            </div>
                            <div class="pc-top-right-item">
                                <div class="item-title">
                                    {{ $t('newFooter.contact') }}
                                </div>

                                <div class="item-content">
                                    <div class="content link">
                                        <a
                                            href="tel:525632298934"
                                        >WhatsApp: 5632298934</a>
                                    </div>
                                    <div class="content link">
                                        <a
                                            href="mailto:contacto@credmex.mx"
                                        >{{ $t('newFooter.emailLeft') }}: contacto@credmex.mx</a>
                                    </div>
                                    <div class="content link">
                                        <a
                                            :href="telHref"
                                        >Tel: {{ tel }}</a>
                                    </div>
                                </div>

                                <div
                                    class="item-title"
                                    style="margin-top: 48px;"
                                >
                                    {{ $t('newFooter.workTime') }}
                                </div>
                                <div class="item-content">
                                    <div class="content">
                                        <div>{{ $t('newFooter.time1') }}</div>
                                        <div>{{ $t('newFooter.time2') }}</div>
                                    </div>
                                </div>

                                <div
                                    class="item-title"
                                    style="margin-top: 48px;"
                                >
                                    {{ $t('newFooter.address') }}
                                </div>
                                <div class="item-content">
                                    <div class="content">
                                        {{ $t('newFooter.addressDetail') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- big tv -->
                        <div
                            v-if="pptShow"
                            class="pc-top-right big-tv"
                        >
                            <div class="big-tv-item">
                                <div class="big-tv-item-title">
                                    Credmex
                                </div>
                                <div class="big-tv-item-content">
                                    <div
                                        v-for="(item, idx) in list"
                                        :key="idx"
                                        class="content link"
                                        @click="goPage(item.name)"
                                    >
                                        {{ $t(item.text) }}
                                    </div>
                                </div>
                            </div>

                            <div class="big-tv-item">
                                <div class="big-tv-item-title">
                                    {{ $t('newFooter.customer') }}
                                </div>
                                <div class="big-tv-item-content">
                                    <div
                                        v-for="(item, idx) in list1"
                                        :key="idx"
                                        class="content link"
                                        @click="goOtherPage(item)"
                                    >
                                        {{ $t(item.text) }}
                                    </div>
                                </div>
                            </div>

                            <div class="big-tv-item">
                                <div
                                    class="big-tv-item-title"
                                >
                                    {{ $t('newFooter.social') }}
                                </div>

                                <div class="icon-wrap">
                                    <img
                                        v-for="(item, idx) in list3"
                                        :key="idx"
                                        :src="item.icon"
                                        class="icon link"
                                        @click="goSocialPage(item.url)"
                                    >
                                </div>
                            </div>

                            <div class="big-tv-item">
                                <div class="big-tv-item-title">
                                    {{ $t('newFooter.contact') }}
                                </div>

                                <div class="big-tv-item-content">
                                    <div class="content link">
                                        <a
                                            href="tel:525632298934"
                                        >WhatsApp: 5632298934</a>
                                    </div>
                                    <div class="content link">
                                        <a
                                            href="mailto:contacto@credmex.mx"
                                        >{{ $t('newFooter.emailLeft') }}: contacto@credmex.mx</a>
                                    </div>
                                    <div class="content link">
                                        <a
                                            :href="telHref"
                                        >Tel: {{ tel }}</a>
                                    </div>
                                </div>
                            </div>

                            <div class="big-tv-item">
                                <div
                                    class="big-tv-item-title"
                                >
                                    {{ $t('newFooter.workTime') }}
                                </div>
                                <div class="big-tv-item-content">
                                    <div class="content">
                                        <div>{{ $t('newFooter.time1') }}</div>
                                        <div>{{ $t('newFooter.time2') }}</div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="big-tv-item"
                                :style="{marginRight: 0}"
                            >
                                <div
                                    class="big-tv-item-title"
                                >
                                    {{ $t('newFooter.address') }}
                                </div>
                                <div class="big-tv-item-content">
                                    <div class="content">
                                        {{ $t('newFooter.addressDetail') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!pptShow"
                        class="pc-bottom"
                    >
                        <div class="pc-bottom-1">
                            <div class="b-title">
                                {{ $t('newFooter.centerTitle') }}
                            </div>
                            <div
                                class="b-content"
                                style="margin-top: 20px;"
                            >
                                <div>{{ $t('newFooter.centerDesc1') }}</div>
                                <div>{{ $t('newFooter.centerDesc2') }}</div>
                                <span>{{ $t('newFooter.tel') }}</span>
                                <a
                                    href="tel:8004610027"
                                    style="text-decoration: underline;"
                                >8004610027</a>
                                <span>{{ $t('newFooter.email') }}</span>
                                <a
                                    href="mailto:contacto@credmex.mx"
                                    style="text-decoration: underline;"
                                >
                                    <span>contacto@credmex.mx</span>
                                </a>
                            </div>
                            <div
                                class="b-content"
                                style="margin-top: 12px;"
                            >
                                {{ $t('newFooter.centerDesc3') }}
                            </div>
                        </div>
                        <div class="pc-bottom-2">
                            <div class="b2-wrap">
                                <div class="b2-content">
                                    {{ $t('newFooter.bottomDesc') }}
                                </div>
                                <div class="b2-content mt20">
                                    {{ $t('newFooter.bottomDesc1') }}
                                </div>
                            </div>
                            <div
                                class="b2-wrap-right"
                                :style="{width: '600px', flex: 'none'}"
                            >
                                <img
                                    src="../img/icon1.png"
                                    class="icon1"
                                >
                                <img
                                    src="../img/icon2.png"
                                    class="icon2"
                                >
                                <img
                                    src="../img/icon3.png"
                                    class="icon3"
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="pptShow"
                        class="pc-bottom big-pc-bottom"
                    >
                        <div class="big-bottom-tv">
                            <div class="pc-bottom-1 big-bottom-item-1">
                                <div class="b-title">
                                    {{ $t('newFooter.centerTitle') }}
                                </div>
                                <div
                                    class="b-content"
                                    style="margin-top: 20px;"
                                >
                                    <div>{{ $t('newFooter.centerDesc1') }}</div>
                                    <div>{{ $t('newFooter.centerDesc2') }}</div>
                                    <span>{{ $t('newFooter.tel') }}</span>
                                    <a
                                        href="tel:8004610027"
                                        style="text-decoration: underline;"
                                    >8004610027</a>
                                    <span>{{ $t('newFooter.email') }}</span>
                                    <a
                                        href="mailto:contacto@credmex.mx"
                                        style="text-decoration: underline;"
                                    >
                                        <span>contacto@credmex.mx</span>
                                    </a>
                                </div>
                                <div
                                    class="b-content"
                                    style="margin-top: 12px;"
                                >
                                    {{ $t('newFooter.centerDesc3') }}
                                </div>
                            </div>
                            <div class="pc-bottom-2  big-bottom-item-2">
                                <div class="b2-wrap">
                                    <div
                                        class="b2-content"
                                        :style="{width: '800px'}"
                                    >
                                        {{ $t('newFooter.bottomDesc') }}
                                    </div>
                                    <div
                                        class="b2-content mt20"
                                        :style="{width: '800px'}"
                                    >
                                        {{ $t('newFooter.bottomDesc1') }}
                                    </div>
                                    <div
                                        class="big-tv-bottom-icon"
                                    >
                                        <img
                                            src="../img/icon1.png"
                                            class="icon1"
                                        >
                                        <img
                                            src="../img/icon2.png"
                                            class="icon2"
                                        >
                                        <img
                                            src="../img/icon3.png"
                                            class="icon3"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bottom-lis">
                    {{ $t('newFooter.fb') }}
                </div>
            </div>
        </div>

        <div class="d-block d-md-none">
            <div class="cm-footer-mobile">
                <div class="inner">
                    <div class="inner-title">
                        {{ $t('newFooter.title') }}
                    </div>

                    <div class="m-logo-wrap">
                        <img
                            class="m-logo-ic"
                            src="../component/borrow/banner/img/appstore-pc.png"
                            alt=""
                            @click="handleDownload('apple')"
                        >
                        <img
                            class="m-logo-ic"
                            src="../component/borrow/banner/img/google-play-pc.png"
                            alt=""
                            @click="handleDownload('google')"
                        >
                    </div>

                    <div class="sub-title">
                        Credmex
                    </div>
                    <div class="item-content">
                        <div
                            v-for="(item, idx) in list"
                            :key="idx"
                            class="content"
                            @click="goPage(item.name)"
                        >
                            {{ $t(item.text) }}
                        </div>
                    </div>

                    <div class="sub-title">
                        {{ $t('newFooter.customer') }}
                    </div>
                    <div class="item-content">
                        <div
                            v-for="(item, idx) in list1"
                            :key="idx"
                            class="content"
                            @click="goOtherPage(item)"
                        >
                            {{ $t(item.text) }}
                        </div>
                    </div>

                    <div class="sub-title">
                        {{ $t('newFooter.social') }}
                    </div>
                    <div class="icon-wrap">
                        <img
                            v-for="(item, idx) in list3"
                            :key="idx"
                            :src="item.icon"
                            class="icon"
                            @click="goSocialPage(item.url)"
                        >
                    </div>

                    <div class="sub-title">
                        {{ $t('newFooter.contact') }}
                    </div>
                    <div class="item-content">
                        <div class="content">
                            <a
                                href="tel:525632298934"
                            >WhatsApp: 5632298934</a>
                        </div>
                        <div class="content">
                            <a
                                href="mailto:contacto@credmex.mx"
                            >{{ $t('newFooter.emailLeft') }}: contacto@credmex.mx</a>
                        </div>
                        <div class="content">
                            <a
                                :href="telHref"
                            >Tel: {{ tel }}</a>
                        </div>
                    </div>

                    <div class="sub-title">
                        {{ $t('newFooter.workTime') }}
                    </div>
                    <div class="item-content">
                        <div class="content">
                            <div>{{ $t('newFooter.time1') }}</div>
                            <div>{{ $t('newFooter.time2') }}</div>
                        </div>
                    </div>

                    <div class="sub-title">
                        {{ $t('newFooter.address') }}
                    </div>
                    <div class="item-content">
                        <div
                            class="content"
                            style="margin-bottom: 0.24rem;"
                        >
                            {{ $t('newFooter.addressDetail') }}
                        </div>
                    </div>

                    <div class="center-inner">
                        <div
                            class="sub-title"
                            style="margin-top: 0;"
                        >
                            {{ $t('newFooter.centerTitle') }}
                        </div>
                        <div class="mt-content">
                            <div>{{ $t('newFooter.centerDesc1') }}</div>
                            <div>{{ $t('newFooter.centerDesc2') }}</div>
                            <div>
                                <span>{{ $t('newFooter.tel') }}</span>
                                <a
                                    href="tel:8004610027"
                                >8004610027</a>
                            </div>
                            <div>
                                <span>{{ $t('newFooter.email') }}</span>
                                <a
                                    href="mailto:contacto@credmex.mx"
                                >contacto@credmex.mx</a>
                            </div>
                        </div>
                        <div class="mt-content">
                            {{ $t('newFooter.centerDesc3') }}
                        </div>
                    </div>

                    <div class="bottom-inner">
                        <div class="mb-content">
                            {{ $t('newFooter.bottomDesc') }}
                        </div>
                        <div class="mb-content mt20r">
                            {{ $t('newFooter.bottomDesc1') }}
                        </div>

                        <img
                            src="../img/icon1.png"
                            class="icon1"
                        >

                        <div class="icon-b-wrap">
                            <img
                                src="../img/icon2.png"
                                class="icon2"
                            >
                            <img
                                src="../img/icon3.png"
                                class="icon3"
                            >
                        </div>
                    </div>
                </div>
                <div class="bottom-tip">
                    {{ $t('newFooter.fb') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {downloadUrl, appleDownloadUrl} from 'credmex-new/common/constant/config';

import Webview from 'easycash/common/resource/Webview';

import Fb from '../img/fb.png';
import Ins from '../img/ins.png';
import TT from '../img/tt.png';
import Tw from '../img/tw.png';
import YB from '../img/yb.png';

export default {
    name: 'CmFooter',

    data() {
        return {
            tel: '',
            pptShow: false,
            list: [
                {
                    text: 'header.borrow',
                    name: 'Borrow'
                },
                {
                    text: 'header.protect',
                    name: 'Protect'
                },
                {
                    text: 'header.blog',
                    name: 'Blog'
                },
                {
                    name: 'Faq',
                    text: 'header.helpCenter'
                }
            ],
            list1: [
                {
                    text: 'newFooter.customProtect.t1',
                    innerLink: {
                        name: 'term',
                        params: {
                            key: 'CREDMEX_TERMS_AND_CONDITIONS'
                        }
                    }
                },
                {
                    text: 'newFooter.customProtect.t2',
                    innerLink: {
                        name: 'term',
                        params: {
                            key: 'CREDMEX_PRIVACY_POLICY'
                        }
                    }
                },
                {
                    text: 'newFooter.customProtect.t3',
                    outerLink: 'https://www.gob.mx/cnbv'
                },
                {
                    text: 'newFooter.customProtect.t4',
                    outerLink: 'https://www.buro.gob.mx'
                },
                {
                    text: 'newFooter.customProtect.t5',
                    outerLink: 'https://www.condusef.gob.mx'
                }
            ],
            list3: [
                {
                    icon: Fb,
                    url: 'https://www.facebook.com/CredmexMexico'
                },
                {
                    icon: TT,
                    url: 'https://www.tiktok.com/@credmex?lang=zh-Hant-TW'
                },
                {
                    icon: YB,
                    url: 'https://www.youtube.com/channel/UCefrb9CdYaVll9X-GshJLWA'
                },
                {
                    icon: Ins,
                    url: 'https://www.instagram.com/credmexmx'
                },
                {
                    icon: Tw,
                    url: 'https://twitter.com/CredmexMexico'
                }
            ]
        };
    },

    computed: {
        telHref() {
            return `tel:${this.tel}`;
        }
    },

    mounted() {
        this.getContact();
        this.handleScreenWidthChange();
        window.addEventListener('resize', this.handleScreenWidthChange);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleScreenWidthChange);
    },

    methods: {
        async getContact() {
            try {
                const {
                    data: {
                        body: {
                            telephone
                        }
                    }
                } = await Webview.getContact({hideLoading: true, hideToast: true});
                this.tel = telephone;
            } catch (err) {
                // ignore
            }
        },

        handleDownload(type) {
            const redirectUrl = 'https://credmex.onelink.me/cf5y/4iva3ujp';
            // eslint-disable-next-line no-nested-ternary
            const url = type === 'google' ? downloadUrl : type === 'apple' ? appleDownloadUrl : redirectUrl;
            window.open(url);
        },

        goPage(routeName) {
            if (this.$route.name === routeName) return;
            this.$router.push({name: routeName});
        },

        goOtherPage({innerLink, outerLink}) {
            if (innerLink) {
                this.$router.push(innerLink);
            } else {
                window.open(outerLink);
            }
        },

        goSocialPage(url) {
            window.open(url);
        },
        handleScreenWidthChange() {
            const screenWidth = window.innerWidth || 0;
            if (screenWidth >= 1920) {
                this.pptShow = true;
            } else {
                this.pptShow = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.mt20 {
    margin-top: 20px;
}

.mt20r {
    margin-top: 0.2rem;
}

.footer {
    background-color: #fff;

    .cm-footer-pc {
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        padding: 100px 40px 0;
        flex-direction: column;
        align-items: center;

        .pc-wrap {
            width: 100%;
            border-radius: 36px;
            background-color: #f3f3fb;

            .pc-top {
                box-sizing: border-box;
                display: flex;
                padding: 60px 80px 40px;
                justify-content: space-between;
                align-items: flex-start;

                .pc-top-left {
                    width: 320px;

                    .foot-title {
                        color: #1e1e1e;
                        font-family: Lexend;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: uppercase;
                    }

                    .footer-ewm {
                        overflow: hidden;
                        position: relative;
                        margin-top: 40px;
                        height: 121px;
                        display: flex;

                        .qr-pic {
                            width: 116px;
                            height: 116px;
                            margin-right: 10px;
                        }

                        .footer-ewm-right {
                            .logo-item {
                                width: 153px;
                                height: 53px;
                                margin-bottom: 10px;
                                cursor: pointer;
                            }
                        }

                        .inner0 {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 204px;
                            height: 103px;
                            box-sizing: border-box;
                            padding: 0 9px 0 12px;
                            display: flex;
                            align-items: center;

                            .inner0-left {
                                flex: 1;
                                color: #1e1e1e;
                                font-family: Lexend;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                text-transform: capitalize;
                            }

                            .inner0-right {
                                margin-left: 5px;
                                width: 84px;
                                height: 84px;
                                border-radius: 8px;
                                background: #fff;
                                overflow: hidden;
                            }
                        }
                    }
                }

                .pc-top-right {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .pc-top-right-item {
                        width: 200px;

                        .item-title {
                            color: #1e1e1e;
                            font-family: Lexend;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 160%;
                        }

                        .item-content {
                            margin-top: 20px;

                            .content {
                                margin-top: 12px;
                                color: #7e7e8a;
                                font-family: Lexend;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 160%;
                            }

                            .link {
                                cursor: pointer;

                                &:hover {
                                    color: #3b3b4a;
                                }
                            }
                        }

                        .icon-wrap {
                            margin-top: 20px;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            cursor: pointer;

                            .icon {
                                width: 64px;
                                height: 64px;
                            }
                        }
                    }
                }

                .big-tv {
                    .big-tv-item {
                        margin-right: 42px;
                        max-width: 200px;

                        .big-tv-item-title {
                            color: #1e1e1e;
                            font-family: Lexend;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 160%;
                        }
                    }

                    .big-tv-item-content {
                        margin-top: 20px;

                        .content {
                            margin-top: 12px;
                            color: #7e7e8a;
                            font-family: Lexend;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 160%;
                        }

                        .link {
                            cursor: pointer;

                            &:hover {
                                color: #3b3b4a;
                            }
                        }
                    }

                    .icon-wrap {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        cursor: pointer;

                        .icon {
                            width: 64px;
                            height: 64px;
                        }
                    }
                }
            }

            .pc-bottom {
                box-sizing: border-box;
                padding-bottom: 140px;

                .pc-bottom-1 {
                    border-top: 1px solid #e6e6ee;
                    box-sizing: border-box;
                    display: flex;
                    padding: 40px 80px;
                    flex-direction: column;
                    align-items: flex-start;

                    .b-title {
                        color: #1e1e1e;
                        font-family: Lexend;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 160%;
                    }

                    .b-content {
                        width: 800px;
                        color: #7e7e8a;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%;
                    }
                }

                .pc-bottom-2 {
                    border-top: 1px solid #e6e6ee;
                    box-sizing: border-box;
                    display: flex;
                    padding: 40px 80px;
                    align-items: flex-start;

                    .b2-wrap {
                        width: 550px;

                        .b2-content {
                            color: #7e7e8a;
                            font-family: Lexend;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 160%;
                        }
                    }

                    .b2-wrap-right {
                        flex: 1;
                        margin-left: 80px;
                        display: flex;
                        justify-content: space-between;

                        .icon1 {
                            width: 223px;
                            height: 40px;
                        }

                        .icon2 {
                            width: 157px;
                            height: 40px;
                        }

                        .icon3 {
                            width: 110px;
                            height: 40px;
                        }
                    }
                }
            }

            .big-pc-bottom {
                padding-bottom: 40px;
            }
        }

        .bottom-lis {
            padding: 40px 0;
            color: #7e7e8a;
            text-align: center;
            font-family: Lexend;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
        }
    }

    .cm-footer-mobile {
        box-sizing: border-box;
        padding: 0.4rem 0.16rem 64px;
        flex-direction: column;
        align-items: center;

        .inner {
            box-sizing: border-box;
            background-color: #f3f3fb;
            overflow: hidden;
            border-radius: 0.16rem;
            display: flex;
            padding: 0.24rem 0.16rem;
            flex-direction: column;
            align-items: flex-start;

            .inner-title {
                color: #1b1b23;
                font-family: Lexend;
                font-size: 0.2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
            }

            .m-logo-wrap {
                display: flex;
                margin-top: 0.24rem;

                .m-logo-ic {
                    width: 1.1rem;
                    height: 0.35rem;
                    margin-right: 0.08rem;
                }
            }

            .sub-title {
                margin-top: 0.24rem;
                color: #3b3b4a;
                font-family: Lexend;
                font-size: 0.14rem;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
            }

            .icon-wrap {
                margin-top: 0.12rem;
                display: flex;

                .icon {
                    width: 0.4rem;
                    height: 0.4rem;
                }
            }

            .item-content {
                margin-top: 0.12rem;

                .content {
                    color: #7e7e8a;
                    font-family: Lexend;
                    font-size: 0.12rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 145%;
                    margin-bottom: 0.08rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .center-inner {
                border-top: 1px solid #e6e6ee;
                border-bottom: 1px solid #e6e6ee;
                display: flex;
                box-sizing: border-box;
                padding: 0.24rem 0;
                flex-direction: column;
                align-items: flex-start;

                .mt-content {
                    margin-top: 0.12rem;
                    color: #7e7e8a;
                    font-family: Lexend;
                    font-size: 0.12rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 145%;
                }
            }

            .bottom-inner {
                box-sizing: border-box;
                display: flex;
                padding: 0.24rem 0;
                flex-direction: column;
                align-items: flex-start;

                .mb-content {
                    color: #7e7e8a;
                    font-family: Lexend;
                    font-size: 0.12rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 145%;
                }

                .icon1 {
                    width: 1.67rem;
                    height: 0.3rem;
                    margin: 0.32rem auto 0.4rem;
                }

                .icon-b-wrap {
                    box-sizing: border-box;
                    padding-left: 0.3rem;
                    margin-bottom: 0.2rem;
                    display: flex;

                    .icon2 {
                        width: 1.17rem;
                        height: 0.3rem;
                    }

                    .icon3 {
                        margin-left: 0.4rem;
                        width: 0.82rem;
                        height: 0.3rem;
                    }
                }
            }
        }

        .bottom-tip {
            margin: 0.2rem 0;
            color: #7e7e8a;
            text-align: center;
            font-family: Lexend;
            font-size: 0.12rem;
            font-style: normal;
            font-weight: 400;
            line-height: 145%;
        }
    }
}

.big-bottom-tv {
    display: flex;
    width: 100%;
}

.big-bottom-item-1 {
    width: 48%;
}

.big-bottom-item-2 {
    width: 48%;
    margin-left: 20px;
}

.big-bottom-item-3 {
    width: 33%;
    display: flex;
    flex-direction: row;
    padding-top: 50px;

    .b2-wrap-right {
        margin-left: 80px;
        display: flex;
        justify-content: space-between;

        .icon1 {
            width: 223px;
            height: 40px;
        }

        .icon2 {
            width: 157px;
            height: 40px;
        }

        .icon3 {
            width: 110px;
            height: 40px;
        }
    }
}

.big-tv-bottom-icon {
    display: flex;
    justify-content: space-between;
    width: 700px;
    margin-top: 50px;

    .icon1 {
        width: 223px;
        height: 40px;
    }

    .icon2 {
        width: 157px;
        height: 40px;
    }

    .icon3 {
        width: 110px;
        height: 40px;
    }
}
</style>
