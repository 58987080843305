<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-20 16:53:16 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:47:49 -->

<template>
    <div class="cm-guide">
        <div
            id="yqg-ocs-btn"
            ref="chat"
            class="chat"
        >
            <img src="../img/chat-sm.png">
            <div class="d-none d-md-block">
                {{ $t('borrow.download.customer') }}
            </div>
        </div>
        <div class="code-box d-none d-md-block">
            <div class="code">
                <img
                    class="code-min"
                    src="../img/code.png"
                >
            </div>
            <div class="download">
                {{ $t('borrow.banner.download') }}
            </div>
        </div>
        <div
            class="code-sm-box d-flex d-md-none"
            @click="handleDownload"
        >
            <span>{{ $t('borrow.banner.downloads') }}</span>
            <img
                class="jump"
                src="../img/jump.png"
            >
        </div>
    </div>
</template>

<script>
import {OCS} from 'credmex-new/common/constant/config';
import Ocs from 'credmex-new/common/util/ocs';

export default {
    name: 'CmGuide',

    data() {
        return {
            isMobile: false
        };
    },

    mounted() {
        this.isMobile = window.innerWidth <= 768;
        const $ocs = new Ocs({
            ...OCS,
            link: `${OCS?.host}/web-chat?locale=es`, // 国际化的话需要增加参数locale=id, id可以根据不同国家更换为id(印度尼西亚)|en（菲律宾）|es（墨西哥）,
            selector: '#yqg-ocs-btn',
            btnVisible: false
        });
        $ocs.init();
    },

    methods: {
        handleDownload() {
            const host = 'https://credmex.onelink.me/cf5y/4iva3ujp';
            window.location.href = host;
        }
    }
};
</script>

<style lang="scss" scoped>
.cm-guide {
    position: fixed;
    bottom: 30px;
    right: 40px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: $screen-md) {
        bottom: 16px;
        right: 16px;
        flex-direction: row;
        align-items: center;
    }

    .chat {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 12px;
        background-color: $primary-purple;
        color: $base-white;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 12px;
        width: fit-content;

        img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
        }

        @media screen and (max-width: $screen-md) {
            padding: 0;
            background-color: transparent;

            img {
                width: 48px;
                height: 48px;
                margin-right: 0;
            }
        }
    }

    .code-box {
        position: relative;
        width: 204px;
        margin-top: 26px;

        .code {
            background-color: #c6f950;
            border-radius: 12px;
            width: 204px;
            height: 104px;
            position: relative;

            .code-min {
                border-radius: 8px;
                width: 84px;
                height: 84px;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

        .download {
            width: 100px;
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            color: #1e1e1e;
            font-family: Lexend;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }
    }

    .code-sm-box {
        width: 152px;
        height: 48px;
        border-radius: 24px;
        background-color: $primary-green;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1e1e1e;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        padding-left: 10px;
        margin-left: 16px;

        .jump {
            width: 40px;
            height: 40px;
        }
    }
}
</style>
