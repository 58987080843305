var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "credmex-new",
      class:
        ((_obj = {}),
        (_obj["credmex-new-" + _vm.$route.name] = _vm.showAnimation),
        _obj),
    },
    [
      _c("cm-header"),
      _vm._v(" "),
      _c("router-view", { attrs: { "show-animation": _vm.showAnimation } }),
      _vm._v(" "),
      _c("div", { staticClass: "anmao", attrs: { id: "cm-footer" } }),
      _vm._v(" "),
      _c("cm-footer"),
      _vm._v(" "),
      _c("cm-guide", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showGuide,
            expression: "showGuide",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }