var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-guide" }, [
    _c(
      "div",
      { ref: "chat", staticClass: "chat", attrs: { id: "yqg-ocs-btn" } },
      [
        _c("img", { attrs: { src: require("../img/chat-sm.png") } }),
        _vm._v(" "),
        _c("div", { staticClass: "d-none d-md-block" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("borrow.download.customer")) +
              "\n        "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "code-box d-none d-md-block" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "download" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$t("borrow.banner.download")) +
            "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "code-sm-box d-flex d-md-none",
        on: { click: _vm.handleDownload },
      },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("borrow.banner.downloads")))]),
        _vm._v(" "),
        _c("img", {
          staticClass: "jump",
          attrs: { src: require("../img/jump.png") },
        }),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "code" }, [
      _c("img", {
        staticClass: "code-min",
        attrs: { src: require("../img/code.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }