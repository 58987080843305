<!--
 * @Author: zhaoyang
 * @Date: 2023-11-09 21:27:59
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-11-27 11:27:09
-->

<template>
    <div class="header">
        <div class="d-none d-md-block">
            <div
                class="cm-header-pc"
            >
                <img
                    src="../img/logo.svg"
                    class="logo"
                    @click="onChange('Borrow', true)"
                >

                <div class="nav-bar">
                    <div
                        v-for="item in tabList"
                        :key="item.name"
                        class="nav"
                        :class="{
                            'nav-active': $route.name === item.name,
                        }"
                        @click="onChange(item.name)"
                    >
                        <span>{{ $t(item.title) }}</span>
                        <div
                            class="line"
                            :class="{
                                'line-full': $route.name === item.name,
                            }"
                        />
                    </div>
                </div>

                <div
                    class="language"
                >
                    <span
                        :class="{active: $i18n.locale === 'en'}"
                        @click="switchLanguage('en')"
                    >EN</span><span>/</span><span
                        :class="{active: $i18n.locale === 'es'}"
                        @click="switchLanguage('es')"
                    >ES</span>
                </div>
            </div>
        </div>

        <div class="d-block d-md-none">
            <div
                class="cm-header-mobile"
            >
                <img
                    src="../img/logo.svg"
                    class="logo"
                    @click="onChange('Borrow', true)"
                >
                <img
                    src="../img/extend.svg"
                    class="extend"
                    @click="onExtend"
                >

                <div
                    class="extend-box"
                    :class="{'is-extend': isExtend}"
                >
                    <div class="item-title">
                        <img
                            src="../img/logo.svg"
                            class="logo"
                        >
                        <img
                            src="../img/close.svg"
                            class="extend"
                            @click="onExtend"
                        >
                    </div>

                    <div class="item-wrap">
                        <div
                            v-for="item in tabList"
                            :key="item.name"
                            class="item"
                            @click="onChange(item.name)"
                        >
                            <div
                                class="left"
                                :class="{
                                    'nav-active': $route.name === item.name
                                }"
                            >
                                {{ $t(item.title) }}
                            </div>
                            <img
                                src="../img/arrow.svg"
                                class="right"
                            >
                        </div>
                        <div class="item">
                            <div class="left" />
                            <div class="language">
                                <span
                                    :class="{active: $i18n.locale === 'en'}"
                                    @click="switchLanguage('en')"
                                >EN</span><span>/</span><span
                                    :class="{active: $i18n.locale === 'es'}"
                                    @click="switchLanguage('es')"
                                >ES</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExtendWhite from '../img/extend-white.png';
import Extend from '../img/extend.svg';
import Logo from '../img/logo.svg';
import LogoWhiteM from '../img/logowhite-m.png';
import LogoWhite from '../img/logowhite.png';

export default {
    name: 'CmHeader',
    props: {
        showGuide: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            Logo,
            LogoWhite,
            LogoWhiteM,
            Extend,
            ExtendWhite,
            isExtend: false,
            isNoFirstTab: false,
            tabList: [
                {name: 'Borrow', title: 'header.borrow'},
                {name: 'Protect', title: 'header.protect'},
                {name: 'Blog', title: 'header.blog'},
                {name: 'Faq', title: 'header.helpCenter'}
            ]
        };
    },

    methods: {
        onChange(routeName, isLogo) {
            if (this.$route.name === routeName) {
                return;
            }

            if (!isLogo) {
                this.onExtend();
            } else {
                this.isExtend = false;
            }

            this.$router.push({name: routeName});
        },

        switchLanguage(lang) {
            this.$i18n.locale = lang;
        },

        onExtend() {
            this.isExtend = !this.isExtend;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~credmex-new/common/style/index-new";

.header {
    z-index: 2;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #fff;

    .cm-header-pc {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        padding: 22px 100px;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 212px;
            height: 36px;

            &:hover {
                cursor: pointer;
            }
        }

        .nav-bar {
            display: flex;
            align-items: center;

            .nav {
                position: relative;
                margin-right: 60px;
                color: #000;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                line-height: normal;

                &:hover {
                    cursor: pointer;

                    .line {
                        width: 100%;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                .line {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    bottom: -4px;
                    background: #1e1e1e;
                    width: 0;
                    height: 3px;
                    transition: all 0.5s ease;
                }

                .line-full {
                    width: 100%;
                }
            }

            .nav-active {
                font-weight: 700;
            }
        }

        .language {
            color: $greyscale-0-l;
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:hover {
                cursor: pointer;
            }

            .active {
                font-weight: 700;
            }
        }

        .language-dark {
            color: $greyscale-0-l;
        }
    }

    .cm-header-mobile {
        position: relative;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 0.5rem;
        padding: 0 0.16rem;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 1.32rem;
            height: 0.36rem;
        }

        .extend {
            width: 0.24rem;
            height: 0.24rem;
        }

        .extend-box {
            transition: all 0.5s ease;
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            height: 0;
            overflow: hidden;
            width: 100%;

            .item-title {
                display: flex;
                height: 0.5rem;
                padding: 0 0.16rem;
                justify-content: space-between;
                align-items: center;
            }

            .item-wrap {
                margin: 0.2rem 0;

                .item {
                    box-sizing: border-box;
                    display: flex;
                    height: 0.5rem;
                    padding: 0 0.16rem;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #e6e6ee;

                    &:last-child {
                        border-bottom: none;
                    }

                    .left {
                        color: #5c5c6a;
                        font-family: Lexend;
                        font-size: 0.14rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .nav-active {
                        font-weight: 700;
                    }

                    .right {
                        width: 0.2rem;
                        height: 0.2rem;
                    }

                    .language {
                        box-sizing: border-box;
                        height: 0.28rem;
                        padding: 0 0.12rem;
                        border-radius: 0.16rem;
                        background: #f3f3fb;
                        color: #3b3b4a;
                        font-family: Lexend;
                        font-size: 0.14rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 0.28rem;
                        text-align: center;
                    }

                    .active {
                        font-weight: 700;
                    }
                }
            }
        }

        .is-extend {
            height: 3.4rem;
        }
    }
}

</style>
