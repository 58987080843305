var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "d-none d-md-block" }, [
      _c("div", { staticClass: "cm-footer-pc" }, [
        _c("div", { staticClass: "pc-wrap" }, [
          _c("div", { staticClass: "pc-top" }, [
            _c("div", { staticClass: "pc-top-left" }, [
              _c("div", { staticClass: "foot-title" }, [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.$t("newFooter.title")) +
                    "\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer-ewm" }, [
                _c("img", {
                  staticClass: "qr-pic",
                  attrs: { src: require("../img/new-qrcode.png") },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "footer-ewm-right" }, [
                  _c("img", {
                    staticClass: "logo-item",
                    attrs: {
                      src: require("../component/borrow/banner/img/appstore-pc.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDownload("apple")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "logo-item",
                    attrs: {
                      src: require("../component/borrow/banner/img/google-play-pc.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDownload("google")
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            !_vm.pptShow
              ? _c("div", { staticClass: "pc-top-right" }, [
                  _c("div", { staticClass: "pc-top-right-item" }, [
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v(
                        "\n                                Credmex\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-content" },
                      _vm._l(_vm.list, function (item, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass: "content link",
                            on: {
                              click: function ($event) {
                                return _vm.goPage(item.name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t(item.text)) +
                                "\n                                "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pc-top-right-item" }, [
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.customer")) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-content" },
                      _vm._l(_vm.list1, function (item, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass: "content link",
                            on: {
                              click: function ($event) {
                                return _vm.goOtherPage(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t(item.text)) +
                                "\n                                "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item-title",
                        staticStyle: { "margin-top": "48px" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("newFooter.social")) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "icon-wrap" },
                      _vm._l(_vm.list3, function (item, idx) {
                        return _c("img", {
                          key: idx,
                          staticClass: "icon link",
                          attrs: { src: item.icon },
                          on: {
                            click: function ($event) {
                              return _vm.goSocialPage(item.url)
                            },
                          },
                        })
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pc-top-right-item" }, [
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.contact")) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-content" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "content link" }, [
                        _c(
                          "a",
                          { attrs: { href: "mailto:contacto@credmex.mx" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("newFooter.emailLeft")) +
                                ": contacto@credmex.mx"
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content link" }, [
                        _c("a", { attrs: { href: _vm.telHref } }, [
                          _vm._v("Tel: " + _vm._s(_vm.tel)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item-title",
                        staticStyle: { "margin-top": "48px" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("newFooter.workTime")) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-content" }, [
                      _c("div", { staticClass: "content" }, [
                        _c("div", [_vm._v(_vm._s(_vm.$t("newFooter.time1")))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.$t("newFooter.time2")))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item-title",
                        staticStyle: { "margin-top": "48px" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("newFooter.address")) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-content" }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.$t("newFooter.addressDetail")) +
                            "\n                                "
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.pptShow
              ? _c("div", { staticClass: "pc-top-right big-tv" }, [
                  _c("div", { staticClass: "big-tv-item" }, [
                    _c("div", { staticClass: "big-tv-item-title" }, [
                      _vm._v(
                        "\n                                Credmex\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "big-tv-item-content" },
                      _vm._l(_vm.list, function (item, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass: "content link",
                            on: {
                              click: function ($event) {
                                return _vm.goPage(item.name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t(item.text)) +
                                "\n                                "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "big-tv-item" }, [
                    _c("div", { staticClass: "big-tv-item-title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.customer")) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "big-tv-item-content" },
                      _vm._l(_vm.list1, function (item, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass: "content link",
                            on: {
                              click: function ($event) {
                                return _vm.goOtherPage(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t(item.text)) +
                                "\n                                "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "big-tv-item" }, [
                    _c("div", { staticClass: "big-tv-item-title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.social")) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "icon-wrap" },
                      _vm._l(_vm.list3, function (item, idx) {
                        return _c("img", {
                          key: idx,
                          staticClass: "icon link",
                          attrs: { src: item.icon },
                          on: {
                            click: function ($event) {
                              return _vm.goSocialPage(item.url)
                            },
                          },
                        })
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "big-tv-item" }, [
                    _c("div", { staticClass: "big-tv-item-title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.contact")) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "big-tv-item-content" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "content link" }, [
                        _c(
                          "a",
                          { attrs: { href: "mailto:contacto@credmex.mx" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("newFooter.emailLeft")) +
                                ": contacto@credmex.mx"
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content link" }, [
                        _c("a", { attrs: { href: _vm.telHref } }, [
                          _vm._v("Tel: " + _vm._s(_vm.tel)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "big-tv-item" }, [
                    _c("div", { staticClass: "big-tv-item-title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.workTime")) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "big-tv-item-content" }, [
                      _c("div", { staticClass: "content" }, [
                        _c("div", [_vm._v(_vm._s(_vm.$t("newFooter.time1")))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.$t("newFooter.time2")))]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "big-tv-item", style: { marginRight: 0 } },
                    [
                      _c("div", { staticClass: "big-tv-item-title" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("newFooter.address")) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "big-tv-item-content" }, [
                        _c("div", { staticClass: "content" }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$t("newFooter.addressDetail")) +
                              "\n                                "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          !_vm.pptShow
            ? _c("div", { staticClass: "pc-bottom" }, [
                _c("div", { staticClass: "pc-bottom-1" }, [
                  _c("div", { staticClass: "b-title" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("newFooter.centerTitle")) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "b-content",
                      staticStyle: { "margin-top": "20px" },
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("newFooter.centerDesc1"))),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("newFooter.centerDesc2"))),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("newFooter.tel")))]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticStyle: { "text-decoration": "underline" },
                          attrs: { href: "tel:8004610027" },
                        },
                        [_vm._v("8004610027")]
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("newFooter.email")))]),
                      _vm._v(" "),
                      _vm._m(2),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "b-content",
                      staticStyle: { "margin-top": "12px" },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("newFooter.centerDesc3")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pc-bottom-2" }, [
                  _c("div", { staticClass: "b2-wrap" }, [
                    _c("div", { staticClass: "b2-content" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.bottomDesc")) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "b2-content mt20" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.bottomDesc1")) +
                          "\n                            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "b2-wrap-right",
                      style: { width: "600px", flex: "none" },
                    },
                    [
                      _c("img", {
                        staticClass: "icon1",
                        attrs: { src: require("../img/icon1.png") },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "icon2",
                        attrs: { src: require("../img/icon2.png") },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "icon3",
                        attrs: { src: require("../img/icon3.png") },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pptShow
            ? _c("div", { staticClass: "pc-bottom big-pc-bottom" }, [
                _c("div", { staticClass: "big-bottom-tv" }, [
                  _c("div", { staticClass: "pc-bottom-1 big-bottom-item-1" }, [
                    _c("div", { staticClass: "b-title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("newFooter.centerTitle")) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "b-content",
                        staticStyle: { "margin-top": "20px" },
                      },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$t("newFooter.centerDesc1"))),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm.$t("newFooter.centerDesc2"))),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("newFooter.tel")))]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticStyle: { "text-decoration": "underline" },
                            attrs: { href: "tel:8004610027" },
                          },
                          [_vm._v("8004610027")]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("newFooter.email")))]),
                        _vm._v(" "),
                        _vm._m(3),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "b-content",
                        staticStyle: { "margin-top": "12px" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("newFooter.centerDesc3")) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pc-bottom-2  big-bottom-item-2" }, [
                    _c("div", { staticClass: "b2-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass: "b2-content",
                          style: { width: "800px" },
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$t("newFooter.bottomDesc")) +
                              "\n                                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "b2-content mt20",
                          style: { width: "800px" },
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$t("newFooter.bottomDesc1")) +
                              "\n                                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(4),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom-lis" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("newFooter.fb")) +
              "\n            "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-block d-md-none" }, [
      _c("div", { staticClass: "cm-footer-mobile" }, [
        _c("div", { staticClass: "inner" }, [
          _c("div", { staticClass: "inner-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("newFooter.title")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-logo-wrap" }, [
            _c("img", {
              staticClass: "m-logo-ic",
              attrs: {
                src: require("../component/borrow/banner/img/appstore-pc.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  return _vm.handleDownload("apple")
                },
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "m-logo-ic",
              attrs: {
                src: require("../component/borrow/banner/img/google-play-pc.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  return _vm.handleDownload("google")
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v("\n                    Credmex\n                "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-content" },
            _vm._l(_vm.list, function (item, idx) {
              return _c(
                "div",
                {
                  key: idx,
                  staticClass: "content",
                  on: {
                    click: function ($event) {
                      return _vm.goPage(item.name)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t(item.text)) +
                      "\n                    "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("newFooter.customer")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-content" },
            _vm._l(_vm.list1, function (item, idx) {
              return _c(
                "div",
                {
                  key: idx,
                  staticClass: "content",
                  on: {
                    click: function ($event) {
                      return _vm.goOtherPage(item)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t(item.text)) +
                      "\n                    "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("newFooter.social")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "icon-wrap" },
            _vm._l(_vm.list3, function (item, idx) {
              return _c("img", {
                key: idx,
                staticClass: "icon",
                attrs: { src: item.icon },
                on: {
                  click: function ($event) {
                    return _vm.goSocialPage(item.url)
                  },
                },
              })
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("newFooter.contact")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item-content" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("a", { attrs: { href: "mailto:contacto@credmex.mx" } }, [
                _vm._v(
                  _vm._s(_vm.$t("newFooter.emailLeft")) +
                    ": contacto@credmex.mx"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("a", { attrs: { href: _vm.telHref } }, [
                _vm._v("Tel: " + _vm._s(_vm.tel)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("newFooter.workTime")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item-content" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", [_vm._v(_vm._s(_vm.$t("newFooter.time1")))]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.$t("newFooter.time2")))]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("newFooter.address")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item-content" }, [
            _c(
              "div",
              {
                staticClass: "content",
                staticStyle: { "margin-bottom": "0.24rem" },
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("newFooter.addressDetail")) +
                    "\n                    "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "center-inner" }, [
            _c(
              "div",
              { staticClass: "sub-title", staticStyle: { "margin-top": "0" } },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("newFooter.centerTitle")) +
                    "\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mt-content" }, [
              _c("div", [_vm._v(_vm._s(_vm.$t("newFooter.centerDesc1")))]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.$t("newFooter.centerDesc2")))]),
              _vm._v(" "),
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("newFooter.tel")))]),
                _vm._v(" "),
                _c("a", { attrs: { href: "tel:8004610027" } }, [
                  _vm._v("8004610027"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("newFooter.email")))]),
                _vm._v(" "),
                _c("a", { attrs: { href: "mailto:contacto@credmex.mx" } }, [
                  _vm._v("contacto@credmex.mx"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-content" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$t("newFooter.centerDesc3")) +
                  "\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bottom-inner" }, [
            _c("div", { staticClass: "mb-content" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$t("newFooter.bottomDesc")) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-content mt20r" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$t("newFooter.bottomDesc1")) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "icon1",
              attrs: { src: require("../img/icon1.png") },
            }),
            _vm._v(" "),
            _vm._m(6),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom-tip" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("newFooter.fb")) +
              "\n            "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content link" }, [
      _c("a", { attrs: { href: "tel:525632298934" } }, [
        _vm._v("WhatsApp: 5632298934"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content link" }, [
      _c("a", { attrs: { href: "tel:525632298934" } }, [
        _vm._v("WhatsApp: 5632298934"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticStyle: { "text-decoration": "underline" },
        attrs: { href: "mailto:contacto@credmex.mx" },
      },
      [_c("span", [_vm._v("contacto@credmex.mx")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticStyle: { "text-decoration": "underline" },
        attrs: { href: "mailto:contacto@credmex.mx" },
      },
      [_c("span", [_vm._v("contacto@credmex.mx")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "big-tv-bottom-icon" }, [
      _c("img", {
        staticClass: "icon1",
        attrs: { src: require("../img/icon1.png") },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon2",
        attrs: { src: require("../img/icon2.png") },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon3",
        attrs: { src: require("../img/icon3.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("a", { attrs: { href: "tel:525632298934" } }, [
        _vm._v("WhatsApp: 5632298934"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-b-wrap" }, [
      _c("img", {
        staticClass: "icon2",
        attrs: { src: require("../img/icon2.png") },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon3",
        attrs: { src: require("../img/icon3.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }