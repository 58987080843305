/*
 * @Author: wuhuiwang
 * @Date: 2022-12-06 21:33:44
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2022-12-06 21:33:44
 */

/**
 * 插入ocs的js脚本
 *
 * @class
 * @param {string} host
 * @param {string} link
 * @param {string} selector
 * @param {boolean} btnVisible
 * @param {string} btnPrimary
 */
class Ocs {

    constructor(params) {
        this.params = [[params]];
        this.$params = params;
    }

    init() {
        const {$params: {host}} = this;
        const self = this;

        (function () {
            const newScript = document.createElement('script');
            Object.assign(newScript, {async: 1, src: `${host}/js/ocsApi.js`});

            const script = document.getElementsByTagName('script')[0];
            script.parentNode.insertBefore(newScript, script);
            newScript.ocs = self;
        })();
    }

}

export default Ocs;
